





























































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PublicBoardTemplates} from "@/graphql/boardTemplate";
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";
import CreateGoalTips from "@/components/general/CreateGoalTips.vue";
import NorthStarService from "@/NorthStarService";
import {PublicBoardTemplatePaginator} from "@/typescript/types";

//@ts-ignore
import VLazyImage from "v-lazy-image/v2";

@Component({
  components: {CreateGoalTips, AddOrLinkNorthStar, VLazyImage},
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
      variables() {
        return {
          page: this.publicBoardTemplatesPageCount
        }

      },
      result(result) {
       
        if (result && result.data && result.data.PublicBoardTemplates && this.PublicBoardTemplatesData && this.PublicBoardTemplatesData.data.length > 0) {
          this.PublicBoardTemplatesData.data  = this.filterDuplicateData([...this.PublicBoardTemplatesData.data, ...result.data.PublicBoardTemplates.data]);
          this.PublicBoardTemplatesData.paginatorInfo = result.data.PublicBoardTemplates.paginatorInfo;

        }else{
          this.PublicBoardTemplatesData = result.data.PublicBoardTemplates;
        }
      },
    },
  }
})
export default class TemplatesDashboardView extends Vue {
  activeGoal: Object | null = null;
  minimize: boolean = false;
  startFromScratch: boolean = false;
  showTemplateDescriptions: boolean = false;
  service: NorthStarService | null = null;
  addedBoard: string | null = null;
  selectedSuggestions: Array<string> = [];
  goalAddDisabled: boolean = true;
  manuallyAddedGoal: boolean = false;
  publicBoardTemplatesPageCount: number = 1;
  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;
  selectedObjectives: Array<string> = [];
  onboardingStep: number = 0;
  addedNorthstars: Record<string, string> = {};
  welcomeStep: number = 0;
  fadeCheck: boolean = false;
  fadeSpot1: boolean = false;
  fadeSpot2: boolean = false;
  fadeText1: boolean = false;
  fadeText2: boolean = false;
  fadeBackground: boolean = false;
  isSlideOut: boolean = false;

  colors: Array<string> = [
    '#7b7343',
    '#4a677d',
    '#773f75',
    '#bd5fb4',
    '#4a677d',
    '#374ea9',
    '#776015',
    '#2e825f',
    '#4e6068',
    '#66737a',
    '#717171',
    'No color'
  ];

  @Prop({default: null, required: false})
  startDate!: Date;

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get currentBoardTemplateId() {
    if(this.board.board_template) {
      return this.board.board_template.id;
    }
    return null;

  }

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }

  get dndOnboarding() {
    return this.$store.state.dnd_onboarding;
  }

  finishOnboarding() {
    this.isSlideOut = true;
    setTimeout(() => {
      this.$store.commit('set_template_onboarding', false);
     
      this.$router.replace({
        name: 'room',
        params: {
          board_pid: this.$store.state.board.pid,
          workspace_pid: this.$store.state.active_workspace_pid,
        }
      });
    }, 2000); // Adjust this timeout to match the animation duration
  }

  addParentIdToObjectives(objectives: any, parentId: any) {
    return objectives.map((objective: any) => {
      return {
        ...objective,  // spread the original objective properties
        parent_id: parentId  // add the new parent_id field
      };
    });
  }


  ifAlreadyAdded(northstarId: string) {

    return this.selectedSuggestions.includes(northstarId);
  }

  ifObjectiveAlreadyAdded(objectiveId: string) {

    return this.selectedObjectives.includes(objectiveId);
  }

  mounted() {
    this.$gtag.event('pmb_app_create_northstar_modal_mounted');
    this.service = new NorthStarService(this.$apollo);

    if(this.templateOnboarding) {
      this.welcomeStep = 1;
      

      setTimeout(() => {
        this.welcomeStep = 2;
        this.fadeText1 = true;
        setTimeout(() => {
          this.fadeText2 = true;
          setTimeout(() => {
            this.welcomeStep = 0;
          }, 1000);
        }, 2300);
        
      }, 2000);
     
    }

    if(this.currentBoardTemplateId) {
      this.templateItemClicked(this.currentBoardTemplateId);
    }

    if(this.board) {
      this.addedBoard = this.board.id;
    }
  }

  templateItemClicked(templateId: string) {
    this.minimize = false;
    this.$nextTick(function(){
      //@ts-ignore ToDo: fix type issue
      window.location.href = '#' + templateId;
    });
  }

  getCoverImage(url: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path['boardTemplateCover']+url;
  }

  manuallyAdded() {
    setTimeout(() => {
      //@ts-ignore
      this.$parent.close();
    }, 400);

  }

  publicBoardTemplatesLoadMore() {
    this.publicBoardTemplatesPageCount++;
    this.$apollo.queries.PublicBoardTemplates.refetch();
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

 

  copyNorthstar(northstar_id: string) {
    this.onboardingStep++;
    this.$gtag.event('pmb_create_ns_from_templates');

    if (this.ifAlreadyAdded(northstar_id)) {
      return;
    }

    if (this.$store.state.board) {
      this.addedBoard = this.$store.state.board.id;
    }

    if (this.selectedSuggestions.length === 0 || this.addedBoard != null) {
      this.selectedSuggestions.push(northstar_id);

      this.service?.copyNorthStar(northstar_id, this.addedBoard, this.workspace.id).then((res: any) => {
        if (res.data.copyNorthStar && res.data.copyNorthStar.boards.length > 0) {
          const newNorthStar = res.data.copyNorthStar;
          const newNorthStarId = newNorthStar.id;

          // Add parentId and newNorthStarId to addedNorthstars
          console.log('parerent id: ' + northstar_id + ' newNorthStarId: ' + newNorthStarId);
          this.$set(this.addedNorthstars, northstar_id, newNorthStarId);

          this.$events.fire('new_northstar_created', res.data.copyNorthStar)

          this.addedBoard = newNorthStar.boards[0].id;
          this.$store.commit('set_newly_created_northstar_count', 
            (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) 
              ? 1 
              : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1
          );
          this.$events.fire('northstars-updated-my-northstar', newNorthStar);
        }
      });
    }
  }

  handleCopyObjective(objective: any) {
    const parentId = objective.parent_id;
    this.onboardingStep++;

    if (this.addedNorthstars[parentId]) {
      // The parentId exists in addedNorthstars, so copy the objective using the existing northstar_id
      const existingNorthstarId = this.addedNorthstars[parentId];
      this.copyObjective(objective.id, existingNorthstarId);
      this.selectedObjectives.push(objective.id);
    } else {
      // The parentId doesn't exist, call copyNorthstar with parentId as the northstar_id
      this.service?.copyNorthStar(parentId, this.addedBoard, this.workspace.id)
        .then((res) => {
          const newNorthStar = res.data.copyNorthStar;
          const newNorthstarId = res.data.copyNorthStar.id;
          this.$events.fire('new_northstar_created', res.data.copyNorthStar)
          this.selectedSuggestions.push(parentId);
          if (res.data.copyNorthStar && res.data.copyNorthStar.id) {
            this.$events.fire('northstars-updated-my-northstar', newNorthStar);
            
            // Store the new northstar_id in addedNorthstars
            this.$set(this.addedNorthstars, parentId, newNorthstarId);
            // Copy the objective using the new northstar_id
            this.copyObjective(objective.id, newNorthstarId);
            this.selectedObjectives.push(objective.id);
          }
        })
        .catch((error) => {
          console.error('Error copying Northstar:', error);
        });
    }
  }

  copyObjective(objectiveId: string, northstarId: string) {
    let boardId = this.$store.state.board?.id || null;
    if (boardId && northstarId) {
      this.service?.copyObjectiveForNorthStar(northstarId, objectiveId, boardId)
        .then((res) => {
          this.$events.fire('objective-created-from-northstar', {
            objective: res.data.copyObjectiveForNorthstar,
            northStarId: northstarId,
          });
      
        })
        .catch((error) => {
          console.error('Failed to copy objective:', error);
        });
    }
  }


  
}
