import { render, staticRenderFns } from "./TemplatesDashboardView.vue?vue&type=template&id=4492f9d5&scoped=true&"
import script from "./TemplatesDashboardView.vue?vue&type=script&lang=ts&"
export * from "./TemplatesDashboardView.vue?vue&type=script&lang=ts&"
import style0 from "./TemplatesDashboardView.vue?vue&type=style&index=0&id=4492f9d5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4492f9d5",
  null
  
)

export default component.exports